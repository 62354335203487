






















































































import { Component, Vue } from 'vue-property-decorator';
import GSignInButton from 'vue-google-signin-button';
import { appName } from '@/env';
import { googleClientId } from '@/env';
import { readLoginError } from '@/store/main/getters';
import { dispatchLogInSlack, dispatchBindSlackSocial } from '@/store/main/actions';

Vue.use(GSignInButton);

@Component
export default class SlackBind extends Vue {
  public email = '';
  public password = '';
  public appName = appName;
  public googleSignInParams = {
    client_id: googleClientId,
  };
  public get loginError() {
    return readLoginError(this.$store);
  }
  public async onSignInSuccess(googleUser) {
    const token = googleUser.getAuthResponse().id_token;
    const provider = 'google';
    const nonce = this.$router.currentRoute.params.nonce;
    await dispatchBindSlackSocial(this.$store, { provider, nonce, token });
  }
  public onSignInError(error) {
    // This already get handled, but the component requires this function.
  }
  public submit() {
    dispatchLogInSlack(this.$store, {
      username: this.email,
      password: this.password,
      nonce: this.$router.currentRoute.params.nonce,
    });
  }
}
